import {
  Badge,
  Box,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  Tooltip
} from "@chakra-ui/react";
import React from "react";

import axios from "axios";
import { useQuery, useQueryClient } from "react-query";

import { PropagateLoader } from "react-spinners";
import PulseLoaderComp from "../../components/queryMutatuinStates/PulseLoaderComp";
import { useAuth } from "../../hooks/auth-hook";

export const OpenSparePart = (props) => {
  const { isOpen, onClose } = props;
  const { token, type, logout } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const queryClient = new useQueryClient();



  const getRequisitionNoitfication = useQuery(
    ["getRequisition"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}requisition-notifications`,
        {
          headers,

        }
      ),

    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );



  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="md"
      sx={{
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#046D76",
          outline: "1px solid slategrey",
          borderRadius: "10px",
        },
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {getRequisitionNoitfication.isSuccess ? (
          <Stack
            fontSize={"18px"}
            py="3%"
            spacing={"10px"}
            sx={{
              overflowY: "scroll",
            }}
          >
            <HStack justifyContent="space-between" margin={9} align={"center"}>
              <Heading fontSize={"xl"} textAlign={"center"} color={"#046D76"}>
                Spare Part Requestion
              </Heading>
              <Badge colorScheme="green" borderRadius="full" px="2">
                {getRequisitionNoitfication?.data?.data?.length}{" "}
              </Badge>
            </HStack>

            {(getRequisitionNoitfication.isFetching) && (
              <Center my={"5"}>
                <PropagateLoader margin={"25px"} size={19} color="#046D76" />
              </Center>
            )}

            {getRequisitionNoitfication?.data?.data?.length > 0 ? (
              <Stack px="4%" fontSize={"16px "}>
                {getRequisitionNoitfication?.data?.data?.map((item) => (
                  <Box
                    key={item?.id}
                    p={4}
                    shadow="xl"
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow={"#385898 0px 0.5px 0px"}
                    background={item?.read_at ? "white" : "#E6F4F1"}
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Flex justifyContent="space-between" flexDirection="column">
                      <Tooltip
                        label={item?.service?.service_type?.name}
                        aria-label="A tooltip"
                      >
                        <Text
                          color={item?.read_at ? "#046D76" : "#046D76"}
                          fontWeight={"bold"}
                          fontSize={"14px"}
                        >
                          {item?.service?.service_type?.name}{" "}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Flex
                      direction={"column"}
                      mx={3}
                      align={"center"}
                      justify={"flex-end"}
                    >
                      <Flex direction={"horizontal"} m={3}>
                        <Badge colorScheme="green" borderRadius="full">
                          {/* <Text fontWeight={"light"} color={"#046D76"}>
                            Technician :
                          </Text> */}
                          {item?.technician?.name}{" "}
                        </Badge>
                      </Flex>
                    </Flex>
                    {/* <Flex justifyContent="flex-end">
                      <Button
                        size={"sm"}
                        color={"rgba(4, 109, 118, 0.8)"}
                        border={
                          item?.read_at
                            ? "1px solid #BA8B70"
                            : "1px solid #046D76"
                        }
                        background={item?.read_at ? "transparent" : "#E6F4F1"}
                        fontWeight={"bold"}
                        onClick={() => {
                          approveSingle.mutate(item?.id);
                          getOverTime?.refetch();
                        }}
                        disabled={item?.status == 1}
                      >
                        Approve
                      </Button>
                    </Flex> */}
                  </Box>
                ))}
              </Stack>
            ) : (
              <Text textAlign={"center"} color={"#046D76"}>
                No OverTime
              </Text>
            )}
          </Stack>
        ) : (
          <PulseLoaderComp h={"60vh"} />
        )}
      </DrawerContent>
    </Drawer>
  );
};
